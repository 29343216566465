exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collections-bunteduffem-js": () => import("./../../../src/pages/Collections/bunteduffem.js" /* webpackChunkName: "component---src-pages-collections-bunteduffem-js" */),
  "component---src-pages-collections-knj-1-js": () => import("./../../../src/pages/Collections/knj1.js" /* webpackChunkName: "component---src-pages-collections-knj-1-js" */),
  "component---src-pages-collections-pulse-js": () => import("./../../../src/pages/Collections/pulse.js" /* webpackChunkName: "component---src-pages-collections-pulse-js" */),
  "component---src-pages-collections-transfigure-js": () => import("./../../../src/pages/Collections/transfigure.js" /* webpackChunkName: "component---src-pages-collections-transfigure-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-store-index-js": () => import("./../../../src/pages/Store/index.js" /* webpackChunkName: "component---src-pages-store-index-js" */)
}

